import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = (redirectPath = "/login") => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate(redirectPath); // Redirect to login if no token is found
    }
  }, [navigate, redirectPath]);
};

export default useAuth;
