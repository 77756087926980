import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  CardActions, 
  Button, 
  CircularProgress, 
  Alert, 
  Grid, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Avatar,
  Box
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddQuizLink from './AddQuizLink';
import baseUrl from '../config';
import { indigo, lightBlue, blueGrey } from '@mui/material/colors';

const StudentQuizList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}api/teachers/students`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch student data');
        }

        const data = await response.json();
        setStudents(data);
      } catch (error) {
        setError('An error occurred while fetching student data.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleOpenDialog = (studentId) => {
    setSelectedStudentId(studentId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStudentId(null);
  };

  return (
    <Box  sx={{ mt: 4, bgcolor: blueGrey[50], borderRadius: '10px', p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ 
        color: indigo[700], 
        textAlign: 'center', 
        fontWeight: 'bold', 
        fontFamily: "'Montserrat', sans-serif",
        letterSpacing: '2px'
      }}>
        Students Overview
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress sx={{ color: lightBlue[700] }} />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mt: 3 }}>{error}</Alert>
      ) : (
        <Grid container spacing={4}>
          {students.map((student) => (
            <Grid item xs={12} sm={6} md={4} key={student.student.enrollmentId}>
              <Card sx={{ 
                borderRadius: '20px', 
                boxShadow: '0 8px 16px rgba(149, 157, 165, 0.2)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0 12px 24px rgba(149, 157, 165, 0.3)'
                }
              }}>
                <CardContent sx={{ 
                  bgcolor: lightBlue[50], 
                  p: 4, 
                  borderRadius: '20px 20px 0 0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Avatar sx={{ bgcolor: indigo[500], width: 64, height: 64, mb: 3 }}>
                    {student.student.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography variant="h6" sx={{ fontWeight: '700', color: indigo[900], mb: 1 }}>
                    {student.student.name}
                  </Typography>
                </CardContent>
                <CardActions sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  p: 2, 
                  bgcolor: '#ffffff',
                  borderTop: `1px solid ${lightBlue[100]}`
                }}>
                  <Button 
                    variant="contained" 
                    onClick={() => handleOpenDialog(student.student.enrollmentId)}
                    sx={{ 
                      bgcolor: indigo[500], 
                      '&:hover': { bgcolor: indigo[700] },
                      mr: 1,
                      fontWeight: 'bold'
                    }}
                  >
                    Add Quiz Link
                  </Button>
                  <Button 
                    variant="outlined" 
                    component={Link} 
                    to={`/quiz-history/${student.student.enrollmentId}`}
                    sx={{ 
                      borderColor: indigo[500], 
                      color: indigo[500],
                      '&:hover': { borderColor: indigo[700], color: indigo[700] }
                    }}
                  >
                    Check Quiz/Update Score
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

<Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          bgcolor: lightBlue[500], 
          color: 'white', 
          fontWeight: 'bold', 
          mb: 2, 
          padding: '16px 24px', // Adjust padding here
        }}
      >
        Add Quiz Link
      </DialogTitle>
      
      <DialogContent
        sx={{
          bgcolor: lightBlue[50],
          padding: '16px 24px', // Adjust padding here to ensure enough space around the content
        }}
      >
        {selectedStudentId && <AddQuizLink studentId={selectedStudentId} />}
      </DialogContent>
      
      <DialogActions
        sx={{
          padding: '8px 16px', // Adjust the padding for the actions (buttons)
        }}
      >
        <Button 
          onClick={handleCloseDialog} 
          sx={{ color: indigo[500] }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
};

export default StudentQuizList;