import React, { useState } from 'react';
import { TextField, Button, Typography, Alert } from '@mui/material';
import axios from 'axios';
import baseUrl from '../config';

const AddQuizLink = ({ studentId }) => {
  const [quizLink, setQuizLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleAddQuizLink = async () => {
    if (!quizLink) {
      setErrorMessage('Quiz link is required');
      return;
    }

    try {
      const response = await axios.post(baseUrl+'api/teachers/assign-quiz-link', {
        enrollmentId: studentId,
        quizLink: quizLink,
      },{
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
    });
      setSuccessMessage(response.data.message);
      setErrorMessage('');
      setQuizLink('');
    } catch (error) {
      setErrorMessage(error.response.data.message || 'An error occurred');
      setSuccessMessage('');
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        label="Quiz Link"
        variant="outlined"
        value={quizLink}
        onChange={(e) => setQuizLink(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleAddQuizLink} fullWidth>
        Add Quiz Link
      </Button>
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {successMessage}
        </Alert>
      )}
    </div>
  );
};

export default AddQuizLink;
