import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { LinearProgress } from "@mui/material";  // Import LinearProgress from MUI

import App from "./App";
import reportWebVitals from "./reportWebVitals";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            width: "100%",   // Full width
          }}
        >
          <LinearProgress
            sx={{
              width: "80%",  // Width of the loading bar
              height: 10,    // Height of the loading bar
            }}
          />
        </div>
      }
    >
       <App />

    </Suspense>
  </React.StrictMode>,
);
reportWebVitals(console.log); // This will log the metrics to the console

 
