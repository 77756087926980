import React from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from './useAuth';

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  useAuth(redirectPath); // Checks authentication and redirects if necessary

  return <Outlet />; // Renders the child route only if authenticated
};

export default ProtectedRoute;
