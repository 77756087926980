import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Alert, Grid, Card, CardContent, CardActions, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import axios from 'axios';
import baseUrl from '../config';
import { Link } from 'react-router-dom';
import UpdateQuizScore from './UpdateQuizScore'; // Make sure this is the correct path to your component

const ViewStudentQuizHistory = () => {
  const { enrollmentId } = useParams();
  const [quizHistory, setQuizHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  useEffect(() => {
    const fetchQuizHistory = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/teachers/quiz/${enrollmentId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setQuizHistory(response.data.quizHistory || []);
        setError('');
      } catch (error) {
        setError('Failed to fetch quiz history.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuizHistory();
  }, [enrollmentId]);

  const handleOpenDialog = (quiz) => {
    setSelectedQuiz(quiz);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedQuiz(null);
  };

  return (
    <div style={{ padding: '30px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{
        color: '#2c3e50',
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 600,
        letterSpacing: '1px',
        textTransform: 'uppercase',
        mb: 4
      }}>
        Quiz History Overview
      </Typography>

      {loading ? (
        <CircularProgress sx={{ display: 'block', margin: '50px auto', color: '#3498db' }} />
      ) : error ? (
        <Alert severity="error" sx={{ mt: 3 }}>{error}</Alert>
      ) : quizHistory.length === 0 ? (
        <Typography variant="body1" align="center" sx={{ color: '#7f8c8d', fontStyle: 'italic', mt: 4 }}>
          No quiz history available for this enrollment.
        </Typography>
      ) : (
        <Grid container spacing={4} justifyContent="center">
          {quizHistory.map((quiz, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{
                borderRadius: '15px',
                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
                transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                '&:hover': {
                  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                  transform: 'scale(1.01)'
                }
              }}>
                <CardContent sx={{ backgroundColor: '#ecf0f1', borderRadius: '15px 15px 0 0', padding: '20px' }}>
                  <Typography variant="h6" sx={{ fontWeight: 700, color: '#2c3e50', mb: 1 }}>
                    Quiz: {quiz.quizLink}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#34495e' }}>
                    Score: {quiz.quizScore || 'Pending'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#34495e' }}>
                    Status: {quiz.quizPassed ? 'Passed' : 'Failed'}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', paddingBottom: '20px' }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    onClick={() => handleOpenDialog(quiz)} // Open dialog with the quiz data
                    sx={{
                      backgroundColor: '#3498db',
                      '&:hover': {
                        backgroundColor: '#2980b9',
                      }
                    }}
                  >
                    Update Score
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Dialog for UpdateQuizScore */}
      
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
       
      
      >
        <DialogContent sx={{ padding: 0 }}>
          {selectedQuiz && (
            <UpdateQuizScore
              enrollmentId={enrollmentId} 
              quiz={selectedQuiz} // Pass the selected quiz data to the component
              onClose={handleCloseDialog} // Pass close function to the component
            />
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'transparent' }}>
          <Button onClick={handleCloseDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewStudentQuizHistory;
