import React, { useState, useEffect, Suspense } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

// Lazy load ManubarPage
const ManubarPage = React.lazy(() => import('./Pages/ManubarPage'));

const Layout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Suspense fallback={<div>Loading...</div>}>
        {/* Pass isMobile to ManubarPage */}
        <ManubarPage isMobile={isMobile} />
      </Suspense>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 1,
          marginTop: isMobile ? '64px' : '0', // Offset AppBar height for mobile
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
