import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  Alert, 
  Container 
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

// Custom styling for the paper component
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
//   background: '#f5f5f5',
//   borderRadius: '15px',
}));

// Custom styling for the form
const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

const UpdateQuizScore = ({ studentId, quizLink }) => {
  const [score, setScore] = useState('');
  const [passingScore, setPassingScore] = useState('');
  const [performanceFeedback, setPerformanceFeedback] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleUpdateQuizScore = async () => {
    if (!score || !passingScore) {
      setErrorMessage('Score and passing score are required');
      return;
    }

    try {
      const response = await axios.put('/api/quiz/update', {
        enrollmentId: studentId,
        quizLink: quizLink,
        score: score,
        passingScore: passingScore,
        performanceFeedback: performanceFeedback,
      });
      setSuccessMessage(response.data.message);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred');
      setSuccessMessage('');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper >
        <Typography component="h1" variant="h5" align="center" gutterBottom>
          Update Quiz Score
        </Typography>
        <FormBox>
          <TextField
            margin="normal"
            fullWidth
            label="Score"
            type="number"
            value={score}
            onChange={(e) => setScore(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: 100 }
            }}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            label="Passing Score"
            type="number"
            value={passingScore}
            onChange={(e) => setPassingScore(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: 100 }
            }}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            multiline
            rows={4}
            label="Performance Feedback"
            value={performanceFeedback}
            onChange={(e) => setPerformanceFeedback(e.target.value)}
            variant="outlined"
          />
          <Button 
            fullWidth 
            variant="contained" 
            color="primary" 
            onClick={handleUpdateQuizScore}
            sx={{ mt: 3, mb: 2, bgcolor: '#3f51b5', '&:hover': { bgcolor: '#303f9f' } }}
          >
            Update Quiz Score
          </Button>
          {errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
          {successMessage && <Alert severity="success" sx={{ mt: 2 }}>{successMessage}</Alert>}
        </FormBox>
      </StyledPaper>
    </Container>
  );
};

export default UpdateQuizScore;