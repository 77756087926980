import React, { Suspense,useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ProtectedRoute from './ProtectedRoute';
import ErrorBoundary from './ErrorBoundary';
// import {
//   DashboardPage,
//   Login,
  
// } from './Pages/index';

import Layout from './Layout'; // Adjust import path as needed
import StudentQuizList from './Components/StudentQuizList';
import AddQuizLink from './Components/AddQuizLink';
import ViewStudentQuizHistory from './Components/ViewStudentQuizHistory';
import UpdateQuizScore from './Components/UpdateQuizScore';
const DashboardPage = React.lazy(() => import('./Pages/DashboardPage'));
const Login = React.lazy(() => import('./Pages/Login'));

const AvailabilityCard = React.lazy(() => import('./Pages/AvailabilityCard'));
const MyCourse = React.lazy(() => import('./Pages/MyCourse'));
const MyGroup = React.lazy(() => import('./Pages/MyGroup'));
const MyEarnings = React.lazy(() => import('./Pages/MyEarning'));
const DemoRequestPage = React.lazy(() => import('./Pages/DemoRequestPage'));
const StudentProfilePage = React.lazy(() => import('./Components/ProfileView'));
const MyStudent = React.lazy(() => import('./Pages/MyStudent'));
const AttendanceTable = React.lazy(() => import('./Pages/GetAttendence'));
const UpcomingClass = React.lazy(() => import('./Components/UpcomingClass'));

const App = () => {



  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');

    if (!token || !tokenTimestamp) {
      logout();
      return;
    }

    const currentTime = Date.now();
    const tokenAge = currentTime - parseInt(tokenTimestamp, 10);
    const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;

    if (tokenAge > fiveDaysInMilliseconds) {
      logout();
    }
  }, [logout]);

  return (
    <ErrorBoundary>

      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Layout />}>
                <Route index element={<DashboardPage />} />
                <Route path="/MyCourse" element={<MyCourse />} />
                <Route path="/student" element={<MyStudent />} />
                <Route path="/Calender" element={<AvailabilityCard />} />
                <Route path="/Earning" element={<MyEarnings />} />
                <Route path="/Group" element={<MyGroup />} />
                <Route path="/checkdemo" element={<DemoRequestPage />} />
                <Route path="/attendance/:studentId" element={<AttendanceTable />} />
                <Route path="/profile" element={<StudentProfilePage />} />
                <Route path="/upcomingClasses/:enrollmentId" element={<UpcomingClass />} />
                <Route
            exact
            path="/add-quiz-link/:studentId"
            element={ <AddQuizLink/>}
          />

          {/* Route for viewing a student's quiz history */}
          <Route path="/quiz-history/:enrollmentId" element={<ViewStudentQuizHistory />} />


         

          <Route path="/update-score/:enrollmentId" element={<UpdateQuizScore/>} />


          <Route path="/studentQuizList" element={<StudentQuizList/>} />
              </Route>
            </Route>
          </Routes>
        </LocalizationProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
